import { Dispatch } from 'redux';
import { ActionType } from './actionTypes';
import { Action } from './actions';

export const onChangeJobId = (jobId: string) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.CHANGE_JOBID,
            payload: jobId
        });
    };
};

export const onChangeShape = (shape: object | null) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.CHANGE_SHAPE,
            payload: shape
        });
    };
};

export const onChangeWidth = (width: string) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.CHANGE_WIDTH,
            payload: width
        });
    };
};

export const onChangeHeight = (height: string) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.CHANGE_HEIGHT,
            payload: height
        });
    };
};

export const onChangeLoadingStatus = (isLoaded: boolean) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.CHANGE_LOADING_STATUS,
            payload: isLoaded
        });
    };
};

export const onChangeTaskStatus = (status: object) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.CHANGE_TASK_STATUS,
            payload: status
        });
    };
};

export const onChangeUploadStatusInfo = (statusInfo: object) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.CHANGE_UPLOAD_STATUS_INFO,
            payload: statusInfo
        });
    };
};

export const setResultInfo = (resultInfo: object) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.SET_RESULT_INFO,
            payload: resultInfo
        });
    };
};

export const onChangeFinalTaskStatus = (status: object) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.CHANGE_FINAL_TASK_STATUS,
            payload: status
        });
    };
};

export const setFinalErrorTaskStatusMessage = (errorMessage: any) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.SET_FINAL_ERROR_TASK_STATUS_MESSAGE,
            payload: errorMessage
        });
    };
};

export const setFinalArtwork = (artwork: object | null) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.SET_FINAL_ARTWORK,
            payload: artwork
        });
    };
};

export const setFinalArtworkRenderPosition = (artworkRenderPosition: object | null) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.SET_FINAL_ARTWORK_RENDER_POSITION,
            payload: artworkRenderPosition
        });
    };
};

export const setUploadError = (uploadError: object | null) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.SET_UPLOAD_ERROR,
            payload: uploadError
        });
    };
};

export const setCanvasInitialSize = (size: object | null) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.SET_CANVAS_INITIAL_SIZE,
            payload: size
        });
    };
};

export const setFinalCoefficientScaling = (coefficient: number) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.SET_FINAL_COEFFICIENT_SCALING,
            payload: coefficient
        });
    };
};
