export enum ActionType {
    CHANGE_JOBID = "change_jobId",
    CHANGE_SHAPE = "change_shape",
    CHANGE_WIDTH = "change_width",
    CHANGE_HEIGHT = "change_height",
    CHANGE_LOADING_STATUS = "change_loading_status",
    CHANGE_TASK_STATUS = "task_status",
    CHANGE_UPLOAD_STATUS_INFO = "change_upload_status_info",
    SET_RESULT_INFO = "set_result_info",
    CHANGE_FINAL_TASK_STATUS = "final_task_status",
    SET_FINAL_ERROR_TASK_STATUS_MESSAGE = "set_final_error_task_status_message",
    SET_FINAL_ARTWORK = "set_final_artwork",
    SET_FINAL_ARTWORK_RENDER_POSITION = "set_final_artwork_render_position",
    SET_UPLOAD_ERROR = "set_uplaod_error",
    SET_CANVAS_INITIAL_SIZE = "set_canvas_initial_size",
    SET_FINAL_COEFFICIENT_SCALING = "set_final_coefficient_scaling",
}
